@import 'styles/colors';

.root {
  background-color: $white;
  width: 5rem;
  height: 5rem;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  img {
    height: 2rem;
    width: 2rem;
  }
}

.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .container {
    display: flex;
    flex-direction: column;
  }

  img {
    height: 2rem;
    width: 2rem;
    margin-bottom: 1em;
  }

  .heading {
    font-weight: bold;
  }

  .link {
    margin: 0 0 1em 0.5em;
    height: 2rem;
  }
}
