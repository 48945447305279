@import 'styles/colors';
@import 'styles/breakpoints';

.root {
  background-image: url('./assurance-pattern.svg');
  background-color: $blue;
  color: $white;
  padding: 1.5rem 0;

  .container {
    display: flex;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
      align-items: center;
    }

    .content {
      margin: auto 0;

      @include mobile {
        text-align: center;
      }
    }

    .loiCard {
      width: 19rem;
      min-width: 19rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $cool-grey;
      padding: 0.75rem;

      @include mobile {
        width: 18rem;
        margin-top: 1.5rem;
      }

      .quoteButtons {
        flex-wrap: wrap;
        justify-content: space-evenly;

        a,
        button {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
