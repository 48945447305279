@import 'styles/colors';
@import 'styles/breakpoints';

$searchItemSize: 6em;

.root {
  padding-bottom: 1.5rem;
}

.headerTitle {
  @include mobile {
    font-size: 1.5rem;
    font-weight: 500;
  }
}

.headerSubtitle {
  @include mobile {
    font-size: 0.9rem;
  }
}

.form {
  margin-top: 1.5rem;

  @include mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.select {
  min-width: 16em;

  @include desktop {
    margin-right: 1em;
  }

  @include mobile {
    margin-bottom: 1rem;
  }
}

.submitButton {
  @include desktop {
    margin-top: 0.35em;
  }
}

.results {
  margin-top: 1.5rem;
}

.agent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: $dark-blue;

  &:hover {
    text-decoration: none;
    color: $blue;
  }

  .name {
    font-weight: 500;
    margin-bottom: 3px;
  }
}

.avatar {
  width: $searchItemSize;
  height: $searchItemSize;
  box-shadow: 0 0 0 5px #33333333;
  margin: 0 auto 0.5em auto;
}
